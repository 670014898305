import React, { Fragment } from 'react'
import Showdown from 'showdown'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import CardRelated from '../components/cardRelated'
import Card from '../components/card'
import SolutionBulletsHorizontal from '../components/solutionBulletsHorizontal'
import SolutionText from '../components/solutionText'
import SolutionTextImage from '../components/solutionTextImage'
import CardIcons from '../components/cardIcons.js'
import Cta from '../components/cta'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const SolutionsTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()  

  return (
    <div id="sub-page">
      <Layout>
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}         
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}            
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div
                className={`font-w-400 text-xs-extra-large divlink ${post.html ? 'text-opening-para' : 'text-opening-para-mb0'}`}
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.frontmatter.openingpara), }}
               />
              {post.html && (
                <div
                  className="richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              )}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && 
          <>
            {post.frontmatter.section.map((sections, index) => (
              <Fragment key={index}>
                {sections.type === 'text' && (
                  <SolutionText data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
                )}
                {sections.type === 'bullets horizontal' && (
                  <SolutionBulletsHorizontal data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
                )}
                {sections.type === 'text and image' && (
                  <SolutionTextImage data={sections} sectionClass={sections.class} placement={sections.placement} />
                )}     
              </Fragment>
            ))}
            </>        
         }        

      {post.frontmatter.bragroll && (
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                {post.frontmatter.bragroll.title}
              </h2>
              {post.frontmatter.bragroll.subtitle && 
                <p className="text-medium">{post.frontmatter.bragroll.subtitle}</p>
              }
              {post.frontmatter.bragroll.card && 
                <MDBRow className="pt-5">
                  {post.frontmatter.bragroll.card.map((cards, index) => (
                      <CardIcons
                        key={index}
                        collg="4"
                        colmd="6"
                        title={cards.title}
                        subtitle={cards.subtitle}
                        image={cards.image.childImageSharp.gatsbyImageData}
                        alt={cards.alttext}
                      />
                    ))}
                </MDBRow>
              }
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}         

        {(post.frontmatter.related && post.frontmatter.related.length > 0) &&
          <section className="bg-light-blue">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                  Related products, solutions and news
                  </h2>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                {post.frontmatter.related.map((relate, index) => (
                  <Fragment key={index}>
                    {(relate.document || relate.link) && (
                      <CardRelated
                        key={index}
                        collg={((post.frontmatter.related.length % 4 === 0) && (!post.frontmatter.related.length % 3 === 0)) ? "6" : "4"}
                        colmd="6"
                        height="8.5rem"
                        type={relate.type}
                        title={relate.title}
                        description={relate.description}
                        linktext={relate.linktext}
                        link={relate.link}
                      />
                    )}
                  </Fragment>
                ))}
              </MDBRow>
            </MDBContainer>
          </section>
       }      

      {post.frontmatter.helpfulresources && 
        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left pb-5">
                {post.frontmatter.helpfulresources.title}
              </h2>
              
              {post.frontmatter.helpfulresources.subtitle && (
               <p className="text-medium">
                 {post.frontmatter.helpfulresources.subtitle}
               </p>
              )}

              {post.frontmatter.helpfulresources.helpfulres && 
                <MDBRow>
                  {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => (
                    <Card
                      key={index}
                      collg="4"
                      colmd="6"
                      height="10rem"
                      title={helpfulres.title}
                      subtitle={helpfulres.subtitle}
                      description={helpfulres.description}
                      image={ helpfulres.image.childImageSharp.gatsbyImageData }
                      alttext={helpfulres.alttext}
                      placement={helpfulres.placement}
                      link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                      titleclass="title-small"
                      descriptionClass="text-card-small py-2"
                    />
                  ))}
                </MDBRow>
              }
            </MDBContainer>
          </MDBAnimation>
        </section>
      }      

      {post.frontmatter.cta && 
        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      }
      </Layout>
    </div>
  )
}
export default SolutionsTemplate

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "solutions" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }         
        template
        title
        subtitle
        description
        openingpara
        ctatext
        ctaslug        
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title
          htag
          subtitle
          description
          link
          linktext
          placement
          type
          class
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          feature {
            title
            icon
            description
            link
            linktext
          }
        }        
        related {
          type
          title
          description
          linktext
          link
        }
        bragroll {
          title
          subtitle
          card {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
          }
        }        
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }        
        cta {
          title
          description
          linktext
          link
        }        
      }
    }
  }
`
