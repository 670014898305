import React from 'react'
import Showdown from 'showdown'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faDownload } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'


class solutionTextImage extends React.Component {
  render() {  
    const converter = new Showdown.Converter()
    const Heading = this.props.data.htag ? this.props.data.htag : 'h2'
    const headingClass = this.props.data.htag === 'h3' ? 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium mb-2' : 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3'    
    
    return (
      <section className={ this.props.sectionClass ? this.props.sectionClass : `bg-white` } key={this.props.key}>
        <MDBContainer>
          <MDBRow className={`about-item align-items-top ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >
            <MDBCol md={this.props.data.feature ? '7' : '5'}>
              <GatsbyImage
                image={this.props.data.image.childImageSharp.gatsbyImageData}
                alt={this.props.data.alttext}
                className="alt-bg-image mb-4 mb-md-0"
              />
            </MDBCol>
            <MDBCol md={!this.props.data.feature ? '7' : '5'}>
              {!this.props.data.title.includes('***') && 
                <Heading className={`${headingClass}`}>
                  {this.props.data.title}
                </Heading>
              }
               {this.props.data.subtitle && (
              <p className="text-medium font-w-700 letter-spacing-1 pb-4 richtext">
                {this.props.data.subtitle}
              </p>
               )}
              
              {this.props.data.description &&
              <div
                className="mt-2 font-w-400 text-medium richtext"
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(this.props.data.description),
                }}
              />
              }

              {(this.props.data.link || this.props.data.document) && (
                <>
                  {this.props.data.link ? (
                    <Link to={this.props.data.link} className="nav-link btn btn-mdb-color btn-sm-block mt-3" >
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                      {this.props.data.linktext}
                    </Link>
                  ) : (
                    <a href={this.props.data.document.publicURL} className="nav-link btn btn-mdb-color btn-sm-block mt-3" target="_blank" >
                      <FontAwesomeIcon icon={faDownload} className="mr-2" />
                      {this.props.data.linktext}
                    </a>
                  )}
                </>
              )}

              {this.props.data.feature && this.props.data.feature.length >= 1 && (
                <div>
                  {this.props.data.feature.map((features, index) => (
                    <MDBRow className="pb-4" key={index}>
                        <MDBCol md="2">                     
                          {features.icon ? (
                            <FontAwesomeIcon icon={['fat', features.icon]} size="2x"
                            />
                          ) : (
                            <GatsbyImage
                              image={ features.image.childImageSharp.gatsbyImageData }
                              alt={features.alttext}
                              className="alt-bg-image"
                            />
                          )}
                        </MDBCol>

                        <MDBCol md="10">
                          <span className="text-medium font-w-700"> {features.title} </span>
                          <span className="text-medium"> {features.subtitle} <br /> </span>
                          <span className="text-medium">
                            {features.link ? (
                              <Link to={features.link} className="effect">
                                {features.description}
                              </Link>
                            ) : (
                              features.description
                            )}
                          </span>
                        </MDBCol>
                      </MDBRow>
                  ))}
                </div>
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default solutionTextImage
